<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Konfigurasi'">
          <template v-slot:body v-if="dataLoaded">
            <Form :form="form" :route="'api/settings/1'" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/view/components/configuration/Form.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import { setting, getSetting } from '@/core/services/jwt.service.js'

export default {
  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {},
      // Other
      dataLoaded: false,
    };
  },

  methods: {
    async get() {
      //   this.form = await module.get('settings/' + this.$route.params.id)
      this.form = await module.get("api/settings/1");
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        // this.$router.push('/settings/list')
      } else {
        this.form["_method"] = "put";
        this.dataLoaded = true;
        console.log("form settings", this.form);
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Konfigurasi", route: "" },
      { title: "Edit" },
    ]);
    // Get Data
    this.get();
  },
};
</script>