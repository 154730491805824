<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <b-form-group
        id="input-group-app_name"
        label="Nama Aplikasi:"
        label-for="input-app_name"
      >
        <b-form-input
          id="input-app_name"
          v-model="form.app_name"
          placeholder="Nama Aplikasi"
        ></b-form-input>
        <small class="text-danger">{{ error.app_name }}</small>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-form-group
            id="input-group-phone"
            label="Telepon:"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              placeholder="Telepon"
            ></b-form-input>
            <small class="text-danger">{{ error.phone }}</small>
          </b-form-group>
        </div>
        <div class="col">
          <b-form-group
            id="input-group-mobile_phone"
            label="Telepon Selular:"
            label-for="input-mobile_phone"
          >
            <b-form-input
              id="input-mobile_phone"
              v-model="form.mobile_phone"
              placeholder="Telepon Selular"
            ></b-form-input>
            <small class="text-danger">{{ error.mobile_phone }}</small>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label for="">Logo:</label>
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
              image-container
            "
          >
            <div style="height: 200px; width: 200px; position: relative" class="image-input-wrapper">
              <img
                class="image"
                :src="[uploadedLogoUrl == '' ? form.logo : uploadedLogoUrl]"
              />
            </div>
          </div>
          <b-form-file
            v-model="form.logo"
            @change="onLogoUpload"
            placeholder="Upload Logo"
            drop-placeholder="Drop file here..."
            class="mt-3"
            plain
          ></b-form-file>
        </div>
        <div class="col-6">
          <label for=""
            >Cover Halaman Login:
            <em class="text-muted">(450px X 611px)</em></label
          >
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
              image-container
            "
          >
            <div style="height: 200px; width: 200px; position: relative" class="image-input-wrapper">
              <img
                class="image"
                :src="[
                  uploadedCoverImageUrl == ''
                    ? form.cover_image
                    : uploadedCoverImageUrl,
                ]"
              />
            </div>
          </div>
          <b-form-file
            v-model="form.cover_image"
            placeholder="Upload Logo"
            drop-placeholder="Drop file here..."
            @change="onCoverImageUpload"
            class="mt-3"
            plain
          ></b-form-file>
        </div>
      </div>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary" class="mt-3"
        >Simpan</b-button
      >
      <!-- <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/action-types')"
      >
        Batal
      </b-button> -->
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import JwtService from "@/core/services/jwt.service";
import { setConfiguration } from "@/core/services/jwt.service.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        logo: "",
        brand_logo: "",
        cover_image: "",
        clinic_name: "",
        clinic_address: "",
        phone: "",
        mobile_phone: "",
        pharmacy_name: "",
        pharmacy_address: "",
        pharmacy_phone: "",
        pharmacy_mobile_phone: "",
        appointment_confirmation: "",
        prescription_status_active: "",
      },
      uploadedLogoUrl: "",
      uploadedBrandLogoUrl: "",
      uploadedCoverImageUrl: "",
      btnAccess: false,
    };
  },

  methods: {
    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "2902") {
          this.btnAccess = true;
        }
      }
    },

    onLogoUpload(evt) {
      this.uploadedLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.logo = evt.target.files[0];
      console.log("logo", this.form.logo);
    },

    onBrandLogoUpload(evt) {
      this.uploadedBrandLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.brand_logo = evt.target.files[0];
      console.log("brand_logo", this.form.brand_logo);
    },

    onBrandLogoUpload(evt) {
      this.uploadedBrandLogoUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.brand_logo = evt.target.files[0];
      console.log("brand_logo", this.form.brand_logo);
    },

    onCoverImageUpload(evt) {
      this.uploadedCoverImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.cover_image = evt.target.files[0];
      console.log("cover_image", this.form.cover_image);
    },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        // this.$router.push("/masters/action-types");
        console.log("response in here", response);

        JwtService.setConfiguration(
          JSON.stringify(response.success.item.original)
        );
        this.$root.$emit("configurationEditSuccessfully");
      }
    },
  },

  mounted() {
    this.setActiveMenu();
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>